html {
  scroll-behavior: smooth;
}

body {
  /* --scrollbar-width: calc(100vw - 100%); */
  overflow-y: scroll;
  background-color: #e9ecef !important;
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* LAYOUT */
.hero{
  /* margin-left:0;
  margin-right:0; */
  max-width:100vw;
  height:100vh;
  background-color: white;
}
.content{
  max-width:1200px !important;
  margin:auto;
}
.hero-portrait{
  /* position:absolute;
  bottom:0; */
  /* margin-top:10%; */
  height:100vh;
  width:100%;
  position:relative;
}
.hero-text{
  /* float:right; */
  height:100%;
  /* background-color: yellow !important; */
}
.portrait{
  max-height:95%;
  bottom:0;
  left:0;
  position:absolute;
  float:left;
}
.hero-arrow{
  float:right;
  text-align:right;
  width:100%;
  max-width:400px;
  height:100%;
  padding-right:100px;
  position:absolute;
  bottom:0;
  /* background-color: red; */
}
.arrow{
  z-index: 10;
  float:right;
  position:absolute;
  bottom:0;
  /* text-align:right; */
  /* padding-top:100%; */
  max-width:100px;
  max-height:80px;
  /* padding-right:100px; */
  margin-bottom:180px;
  opacity:0.8;
  cursor: pointer;
}
.hidden {
  display: none;
}
.bg-header {
  /* background-color: white; */
  /* max-width:100vw;
  max-height:100vh; */
  /* margin-left: calc((100vw - 100%) / -2) !important;
  padding-left: calc((100vw - 100%) / 2) !important;
  margin-right: calc((100vw - 100%) / -2) !important;
  padding-right: calc((100vw - 100%) / 2) !important; */
}
.jumbotron {
  margin-top:50px;
  border-radius:30px !important;
}
.title {
  width: 100%;
}
.email{
  color: hsl(16, 100%, 50%) !important;
  font-size:24px;
  padding-top:10px;
  z-index: 20;
}
.more {
  padding-top:20px;
  font-size: 20px;
  text-align:left;
}
.shadow-xl {
  box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.1);
}

/* BUTTONS */
Button {
  border-radius:25px;
}
.email-button {
  border-radius:25px;
}
.btn-link {
  font-size: 24px !important;
  font-weight: 400 !important;
  color: orangered !important;
}

.links {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: orangered !important;
  color: black !important;
}

.btn:focus,.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

/* FONTS */
h2 {
  font-weight:600 !important;
  font-size:50px !important;
}
h3 {
  padding-top:30px;
  padding-bottom:0px;
}
h4 {
  font-weight:400 !important;
  font-size:24px !important;
  line-height:36px !important;
  margin-bottom:0 !important;
}
h5 {
  margin-top:20px !important;
  font-weight:400 !important;
  font-size:20px !important;
  line-height:30px !important;
  margin-bottom:10px !important;
  color: #999 !important;
}

/* EMAIL ENCRIPTION */
/* .cryptedmail:after {
  content: attr(data-name) "@" attr(data-domain) "." attr(data-tld); 
}
.cryptedmail {
  color: hsl(16, 100%, 50%) !important;
}
.cryptedmail:hover {
  text-decoration:none !important;
  cursor: pointer;
} */

/* VIDEO EMBEDING*/
.player .vp-video-wrapper .vp-preview {
  left: -3px !important;
  right: -3px !important;
}

/* FADE IN EFFECT */
.fade-in-section {
  max-width: 1200px;
  margin-bottom:50px;
  margin:auto;
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}


/* LOADER */
.center {
  text-align: center;
}

.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 3em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 2s infinite linear;
  transition: opacity 0.3s;
  background: white;
}

.main {
  animation: fadeIn ease 2s;
}

.loader--hide {
  opacity: 0;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {opacity:0;}
  50% {opacity:0;}
  100% {opacity:1;}
  }